import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { notifySuccess, notifyError } from "../../utils/toast";
import { apiURL, config } from "../../routes/api";

const EnquiryForm = ({ companyId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const enquiryData = {
      message: data.message,
      name: data.name,
      email: data.email,
      phone: data.phone,
      company: companyId,
    };

    axios
      .post(`${apiURL}/api/v1/employer/new/enquiry`, enquiryData, config)
      .then((res) => {
        notifySuccess("Enquiry Submitted");
        setValue("name");
        setValue("email");
        setValue("phone");
        setValue("message");
      })
      .catch((e) => notifyError("Server not responding try again"));
  };

  return (
    <div className="box">
      <div className="heading">Enquire now</div>
      <div className="enquire mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <input
              {...register("name", {
                required: "Name is required",
              })}
              type="text"
              name="name"
              placeholder="Your Name *"
              style={errors.name && { border: "1px solid red" }}
            />
            {errors.name && (
              <label style={{ color: "red", fontSize: ".7rem" }}>
                {errors.name.message}
              </label>
            )}
          </div>
          <div className="input-group">
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              })}
              type="text"
              name="email"
              placeholder="Email *"
              style={errors.email && { border: "1px solid red" }}
            />
            {errors.email && (
              <label style={{ color: "red", fontSize: ".7rem" }}>
                {errors.email.message}
              </label>
            )}
          </div>
          <div className="input-group">
            <input
              {...register("phone", { required: true })}
              type="text"
              name="phone"
              placeholder="Phone No. *"
              style={errors.phone && { border: "1px solid red" }}
            />
            {errors.phone && (
              <label style={{ color: "red", fontSize: ".7rem" }}>
                phone is required
              </label>
            )}
          </div>
          <div className="input-group">
            <textarea
              {...register("message")}
              placeholder="Message"
              name="message"
            ></textarea>
          </div>
          <div className="input-group">
            <input type="submit" value="Submit Enquiry" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnquiryForm;
