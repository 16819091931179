import React from "react";
import Carousel from "./Carousel";

const BusinessGallary = ({ data }) => {
  return (
    <Carousel>
      <div className="owl-stage-outer">
        <div className="owl-stage">
          {data.length > 0 &&
            data.map((item) => {
              return (
                <div className="owl-item" key={item._id}>
                  <div className="item">
                    <img
                      src={
                        item.url === ""
                          ? "https://fisnikde.com/wp-content/uploads/2019/01/broken-image.png"
                          : item.url
                      }
                      alt="gallary-images"
                      height={300}
                      width={100}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "https://fisnikde.com/wp-content/uploads/2019/01/broken-image.png";
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Carousel>
  );
};

export default BusinessGallary;
