import React from "react";

const data = [
  {
    name: "Free-Wifi",
  },
  {
    name: "open 24/7",
  },
  {
    name: "Free Parking",
  },
  {
    name: "No Smoking",
  },
  {
    name: "Reservation",
  },
  {
    name: "Smoking Area",
  },
  {
    name: "Pet Friendly",
  },
  {
    name: "Weekly Discount",
  },
];

const Amenities = ({ amenitiesCheck, setAmenitiesCheck }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setAmenitiesCheck((pre) => [...pre, name]);
    } else {
      setAmenitiesCheck(amenitiesCheck.filter((item) => item !== name));
    }
  };

  return (
    <>
      <label style={{ paddingBottom: 20 }}>Amenities</label>
      <div className="input-group">
        {data.map((item) => (
          <div className="form-check form-check-inline" key={item.name}>
            <input
              className="form-check-input"
              type="checkbox"
              id={item.name}
              value={item.name}
              name={item.name}
              checked={
                amenitiesCheck.filter((ne) => ne === item.name).length 
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={item.name}>
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default Amenities;
