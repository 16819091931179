import React from "react";

const data = [
  {
    name: "Cash",
  },
  {
    name: "Credit/Debit Card",
  },
  {
    name: "Net Banking",
  },
  {
    name: "UPIs",
  },
  {
    name: "Paytm",
  },
  {
    name: "PhonePe",
  },
  {
    name: "Google Pay",
  },
];

export const PaymentSelect = ({ paymentCheck, setPaymentCheck }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setPaymentCheck((pre) => [...pre, name]);
    } else {
      setPaymentCheck(paymentCheck.filter((item) => item !== name));
    }
  };
  return (
    <>
      <label style={{ paddingBottom: 20 }}>Payment Methods Accepted</label>
      <div className="input-group">
        {data.map((item) => (
          <div className="form-check form-check-inline" key={item.name}>
            <input
              className="form-check-input"
              type="checkbox"
              id={item.name}
              value={item.name}
              name={item.name}
              checked={
                paymentCheck.filter((ne) => ne === item.name).length
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={item.name}>
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};
