import React, { useState } from "react";
import { useForm } from "react-hook-form";
import StarRating from "../rating/StarRating";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { newreview } from "../../redux/actions/companyAction";
import { notifyError } from "../../utils/toast";

const ReviewSection = ({ companyId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const [serviceRating, setServiceRating] = useState(0);
  const [priceRating, setPriceRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
  const [locationRating, setLocationRating] = useState(0);
  const [captchaValue, setCaptchaValue] = useState(false);

  const handleCaptcha = (value) => {
    console.log("Captcha value:", value);

    if (value == null) {
      setCaptchaValue(false);
    } else {
      setCaptchaValue(true);
    }
  };

  const onSubmit = (data) => {
    if (
      qualityRating === 0 &&
      qualityRating === 0 &&
      qualityRating === 0 &&
      locationRating === 0
    )
      return notifyError("Please Rate the Business First !");

    if (!captchaValue) return notifyError("Please Verify Captcha ");

    const reviewData = {
      qualityReview: qualityRating,
      serviceReview: qualityRating,
      priceReview: qualityRating,
      locationReview: locationRating,
      comment: data.comment,
      company: companyId,
      email: data.email,
      name: data.name,
    };

    dispatch(newreview(reviewData));

    setValue("comment");
    setValue("email");
    setValue("name");
    setServiceRating(0);
    setPriceRating(0);
    setQualityRating(0);
    setLocationRating(0);
  };

  return (
    <>
      <div className="box mt-4 mb-4">
        <div className="row">
          <div className="col-md-12">
            <h4>Add Review</h4>
            <div className="rating mt-4">
              <ul>
                <li>
                  Service?
                  <br />
                  <StarRating
                    setRating={setServiceRating}
                    rating={serviceRating}
                  />
                </li>
                <li>
                  Price?
                  <br />
                  <StarRating setRating={setPriceRating} rating={priceRating} />
                </li>
                <li>
                  Quality?
                  <br />
                  <StarRating
                    setRating={setQualityRating}
                    rating={qualityRating}
                  />
                </li>
                <li>
                  Location?
                  <br />
                  <StarRating
                    setRating={setLocationRating}
                    rating={locationRating}
                  />
                </li>
              </ul>
            </div>
            <div className="clearfix mb-4"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <label>
                      Name <span className="red">*</span>
                    </label>
                    <input
                      {...register("name", {
                        required: "Name is required",
                      })}
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      style={errors.name && { border: "1px solid red" }}
                    />{" "}
                    {errors.name && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {errors.name.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <label>
                      Email <span className="red">*</span>
                    </label>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                      type="text"
                      placeholder="Your Email"
                      name="email"
                      style={errors.email && { border: "1px solid red" }}
                    />
                    {errors.email && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {errors.email.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-group">
                    <label>Review</label>
                    <textarea
                      {...register("comment", {
                        required: "Enter your review",
                      })}
                      placeholder="Review"
                      name="comment"
                      style={errors.comment && { border: "1px solid red" }}
                    ></textarea>
                    {errors.comment && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {errors.comment.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <ReCAPTCHA
                    sitekey="6LdktFIfAAAAAPrxjGeFQrnEOSP8VW7R5QkRtyVO"
                    onChange={handleCaptcha}
                  />
                  <br />
                </div>
                <div className="col-md-12 input-group">
                  <input type="submit" value="Submit Review" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewSection;
