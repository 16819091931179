import React, { useState } from "react";

const data = [
  {
    name: "Mon",
  },
  {
    name: "Tue",
  },
  {
    name: "Wed",
  },
  {
    name: "Thu",
  },
  {
    name: "Fri",
  },
  {
    name: "Sat",
  },
  {
    name: "Sun",
  },
  {
    name: "24x7",
  },
];

const BusinessHour = ({
  setBusinessDays,
  businessDays,
  setBusinessTime,
  businessTime,
}) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setBusinessDays((pre) => [...pre, name]);
    } else {
      setBusinessDays(businessDays.filter((item) => item !== name));
    }
  };

  return (
    <div className="col-md-12">
      <div className="submit-job-form">
        <div className="title">
          <i className="far fa-clock"></i> Business hour
        </div>
        <div className="form">
          <label style={{ paddingBottom: 20 }}>Select Days</label>
          <div className="input-group">
            {data.map((item) => (
              <div className="form-check form-check-inline" key={item.name}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={item.name}
                  value={item.name}
                  name={item.name}
                  checked={
                    businessDays.filter((ne) => ne === item.name).length
                      ? true
                      : false
                  }
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={item.name}>
                  {item.name}
                </label>
              </div>
            ))}
          </div>
          <div class="row business">
            <lable style={{ paddingBottom: 20 }}>Select Time</lable>
            <div class="col-md-3">
              <div class="input-group">
                <input
                  type="time"
                  onChange={(e) =>
                    setBusinessTime({ ...businessTime, start: e.target.value })
                  }
                  value={businessTime.start}
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="input-group">
                <input
                  type="time"
                  onChange={(e) =>
                    setBusinessTime({ ...businessTime, end: e.target.value })
                  }
                  value={businessTime.end}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessHour;
