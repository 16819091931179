export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

export const SUB_CATEGORY_REQUEST = "SUB_CATEGORY_REQUEST";
export const SUB_CATEGORY_SUCCESS = "SUB_CATEGORY_SUCCESS";
export const SUB_CATEGORY_FAIL = "SUB_CATEGORY_FAIL";

export const AMENITY_REQUEST = "AMENITY_REQUEST";
export const AMENITY_SUCCESS = "AMENITY_SUCCESS";
export const AMENITY_FAIL = "AMENITY_FAIL";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
