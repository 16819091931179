import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { config, apiURL } from "../../routes/api";
import { notifySuccess, notifyError } from "../../utils/toast";
import axios from "axios";
import { toast } from "react-toastify";

const UpdatePhone = ({ history, oldPhone }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm();

  const sendOTP = async (phone) => {
    try {
      const { data } = await axios.post(
        `${apiURL}/api/v1/otp/send`,
        { phone },
        config
      );
      notifySuccess(data.message);
      setStep(step + 1);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const verifyOTP = async (phone, otp) => {
    try {
      const { data } = await axios.post(
        `${apiURL}/api/v1/otp/verify`,
        { phone, otp },
        config
      );
      // notifySuccess(data.message);
      updateNumber(phone);
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  const updateNumber = async (phone) => {
    try {
      const { data } = await axios.put(
        `${apiURL}/api/v1/phone/update`,
        { phone },
        config
      );
      notifySuccess(data.message);
    } catch (error) {
      notifyError(error.response.data.message);
    }
    setStep(0);
    setValue("phone");
    setValue("otp");
  };

  useEffect(() => {
    setStep(0);
    // setValue("phone", oldPhone);
  }, []);

  const [step, setStep] = useState(0);

  const onSubmit = (data) => {
    if (step > 1) {
      return;
    }
    if (step === 0) {
      sendOTP(data.phone);
      return;
    }
    if (step === 1) {
      verifyOTP(data.phone, data.otp);
    }
  };

  const onBack = () => {
    if (step <= 1) {
      return;
    }
    setStep(step - 1);
  };

  return (
    <>
      <button
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasPhone"
        aria-controls="offcanvasPhone"
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Update Mobile Number
      </button>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasPhone"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Update Your Mobile Number</h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {/* <p>{oldPhone}</p> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {step === 0 && (
                <div className="input-group">
                  <div className="col-md-9">
                    <input
                      defaultValue={oldPhone}
                      {...register("phone", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Please enter a valid mobile number",
                        },
                      })}
                      type="text"
                      placeholder="Mobile No."
                      maxLength={10}
                      style={errors.phone && { border: "1px solid red" }}
                    />
                    {errors.phone && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {errors.phone.message}
                      </label>
                    )}
                  </div>
                </div>
              )}
              {step === 1 && (
                <div className="input-group">
                  <div className="col-md-9">
                    <input
                      {...register("otp", {
                        required: "OTP is required",
                      })}
                      type="text"
                      placeholder="Enter OTP"
                      maxLength={6}
                      style={errors.phone && { border: "1px solid red" }}
                    />
                    {errors.otp && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {errors.otp.message}
                      </label>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12 text-center mt-3">
              <div className="input-group">
                {step === 0 && <input type="submit" value="Send OTP" />}
                {step === 1 && (
                  <input type="submit" value="Verify OTP & Update" />
                )}
              </div>
            </div>
          </form>
          {step === 1 && (
            <div className="col-md-12 text-center mt-3">
              <div className="input-group">
                <input
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    onBack();
                  }}
                  value="Back"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdatePhone;
