import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import moment from "moment";
import Modal from "../modal/Modal";

export const MyListCard = ({
  category,
  image,
  name,
  location,
  phone,
  type,
  id,
  RegistrationDate,
  categoryId,
  setToogle,
}) => {
  const history = useHistory();
  return (
    <>
      <Modal setToogle={setToogle} companyId={id} name={name} />

      <div className="col-md-4">
        <div className="listing-gird">
          <div className="image">
            {" "}
            <Link
              to={{
                pathname: `/listDetails/${name.replace(" ", "-")}`,
                state: { id },
              }}
            >
              <img
                src={image}
                className="img-fluid"
                style={{ height: 150 }}
                alt={name}
              />
            </Link>{" "}
          </div>
          <div className="meta">
            {" "}
            <span className="open">{type}</span>{" "}
            <span className="category">
              <Link
                to={{
                  pathname: `business/${category?.replace(" ", "-")}`,
                  state: { keyword: categoryId },
                }}
              >
                {category}
              </Link>
            </span>{" "}
          </div>
          <h2>
            <Link
              to={{
                pathname: `/listDetails/${name.replace(" ", "-")}`,
                state: { id },
              }}
              style={{ textTransform: "capitalize" }}
            >
              {name}
            </Link>
          </h2>
          <div className="meta-info">
            {" "}
            <span className="location">
              <i className="fas fa-map-marker-alt"></i> {location}
            </span>{" "}
            {RegistrationDate && (
              <span className="phone" style={{ display: "block" }}>
                <i className="fas fa-calendar-alt"></i>{" "}
                {moment(RegistrationDate).format("LL")}
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history.push(`/edit/business/${id}`)}
          >
            Edit
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target={`#sanatan${id.substring(1, 8)}`}
            // onClick={() => console.log(id, "id")}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};
