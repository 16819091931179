import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Caption } from "../components/breadcrum/Caption";
import Header from "../components/header/Header";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Caption title="About Us">
        <Header />
      </Caption>
      <section class="padding80">
        <div class="container">
          <div class="row about">
            <div class="col-md-8 welcome-text">
              <h2>Greetings & Welcome to Sanatan Setu</h2>
              <h3>Join Today For a Great Future Together</h3>
              <p class="mt-4">
                Sanatan Setu is India's First Online Business Directory
                Platform, designed exclusively for businesses owned and managed
                by Sanatanis'. You can now easily find Sanatan businesses in
                your neighbourhood or anywhere in Bharat in one place.{" "}
              </p>
              <p class="mt-4">
                <Link to="/login">Sign up</Link> to become a part of the Sanatan
                Setu and help it grow so that Sanatani customers can find you
                and connect with your business.
              </p>
              <div class="row mt-4">
                <div class="col-md-4 mt-3">
                  <div class="number">
                    <span>500,000 K</span>
                    <p>Target Businesses</p>
                  </div>
                </div>
                <div class="col-md-4 mt-3">
                  <div class="number">
                    <span class="color">99%</span>
                    <p>Great Feedback</p>
                  </div>
                </div>
                <div class="col-md-4 mt-3">
                  <div class="number">
                    <span class="color2">5,000 K</span>
                    <p>Active Members</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <img
                src="assets/images/video-img.png"
                class="img-fluid"
                alt="assets"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="pt-5 about-bg">
        <div class="container about">
          <div class="row sign">
            <div class="col-md-6">
              <img
                src="assets/images/about-1.png"
                class="img-fluid"
                alt="assets"
              />
            </div>
            <div class="col-md-6">
              <h2>
                Sanatan Setu <br />
                <span>Signup</span>
              </h2>
              <p class="mt-4 mb-4">
                List your company and address the most difficult business
                challenges, such as finding customers and gaining exposure.
              </p>
              <p>
                <Link to="/listbusiness" class="jt-btn">
                  Add Business
                </Link>
                {/* <a href="#works" class="wt-btn">
                  How It Works
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="padding80">
        <div class="container about">
          <div class="row faq">
            <div class="col-md-12 text-center mb-5">
              <h3>You’ve settled on a business</h3>
              <h2>FAQs</h2>
            </div>
            <div class="col-md-6">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      What Sanatan Setu can provide?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Sanatan Setu is India's First Online Business Directory
                        Platform exclusively for businesses owned and managed by
                        Sanatanis. It provides a single platform where all such
                        businesses can list and do business together.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      How much time it takes to add my business?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>
                        It takes 2 min to register ane get verified on Sanatan
                        Setu and another 10 min to add your business if you have
                        all the information and images handy.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What all services Sanatan Setu plans to offer?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>
                        As a business, you will be able to list Job requirements
                        in addition to business details such as
                        products/services you offer. A user can also post an Ad
                        for an item they want to sell. Registered members can
                        search for and contact sellers to conduct offline
                        transactions. Both of these features will be available
                        soon.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="accordion accordion-flush"
                id="accordionFlushExample2"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading1">
                    <button
                      class="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse1"
                      aria-expanded="false"
                      aria-controls="flush-collapse1"
                    >
                      What are Premium Services?
                    </button>
                  </h2>
                  <div
                    id="flush-collapse1"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-heading1"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div class="accordion-body">
                      <p>
                        As a business you can list yourself as FREE where you do
                        not have to pay anything. For additional exposure of
                        your business, you can upgrade to Featured business
                        service where your business will appear on the Homepage
                        under Featured Businesses section.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading2">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse2"
                      aria-expanded="false"
                      aria-controls="flush-collapse2"
                    >
                      How much time will my business be featured?
                    </button>
                  </h2>
                  <div
                    id="flush-collapse2"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-heading2"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div class="accordion-body">
                      <p>
                        Based on the duration for which you have activated your
                        Featured business plan, your business will appear on the
                        homepage under the Featured Businesses section. You can
                        get your business Featured for a week, 2 weeks or a
                        month.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading3">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse3"
                      aria-expanded="false"
                      aria-controls="flush-collapse3"
                    >
                      How many businesses can I add in Sanatan Setu?
                    </button>
                  </h2>
                  <div
                    id="flush-collapse3"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-heading3"
                    data-bs-parent="#accordionFlushExample2"
                  >
                    <div class="accordion-body">
                      <p>
                        We do not believe in limiting our members and thus there
                        is no limit to the number of business you can add.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <aside class="padding80 background-1"></aside>
    </>
  );
};

export default About;
