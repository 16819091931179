import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTagInput from "@pathofdev/react-tag-input";

//import utils
import { validateBusinessDetails } from "../utils/Validator";
import { notifyError, notifySuccess } from "../utils/toast";

///imports actions
import {
  getCategories,
  getAmenityByCategory,
  clearErrors,
  getSubCategoryById,
} from "../redux/actions/categoryAction";
import { newCompany } from "../redux/actions/companyAction";

/// import components
import { Caption } from "../components/breadcrum/Caption";
import { Loader } from "../components/loader/Loader";
import Header from "../components/header/Header";
import BusinessInformation from "./Business/BusinessInformation";
import BusinessSocials from "./Business/BusinessSocials";
import Gallary from "./Business/Gallary";
import BusinessHour from "./Business/BusinessHour";
import Amenities from "../components/form/Amenities";
import { PaymentSelect } from "../components/form/PaymentSelect";
import ProductCertifiedSelect from "../components/form/ProductCertifiedSelect";

const ListBusiness = ({ history }) => {
  const { category } = useSelector((state) => state.category);
  const { subCategories } = useSelector((state) => state.subCategories);
  const { errors, company } = useSelector((state) => state.newCompany);
  const { isAuthenticated } = useSelector((state) => state.user);
  const { amenity } = useSelector((state) => state.amenity);
  const [selectCategory, setSelectCategory] = useState("");
  const dispatch = useDispatch();
  const [logo, setLogo] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [error, setError] = useState({});
  const errorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [tags, setTags] = React.useState([]);
  const [service, setService] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  useEffect(() => {
    if (company) {
      history.push("/dashboard");
    }
    if (errors) {
      setLoading(false);
    }
  }, [errors, company, history]);

  useEffect(() => {
    dispatch(getCategories());

    if (!isAuthenticated) {
      notifyError("login first");
      history.push("/login?redirect=listbusiness");
    }
  }, [isAuthenticated, history, dispatch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [registrationData, setRegistrationData] = useState({
    businessName: "",
    website: "",
    size: "100",
    founded: "1998",
    tagline: "",
    description: "",
    companyType: "PVT LTD",
    category: "",
    subCategory: "",
    email: "",
    phone: "",
    video: "",
  });

  const [address, setAddress] = useState({
    add: "",
    locality: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    landmark: "",
    googleMap: "",
  });

  const [social, setSocial] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedin: "",
  });

  const [productCheck, setProductCheck] = useState([]);
  const [paymentCheck, setPaymentCheck] = useState([]);
  const [amenitiesCheck, setAmenitiesCheck] = useState([]);
  const [businessDays, setBusinessDays] = useState([]);
  const [businessTime, setBusinessTime] = useState({
    end: "06:00",
    start: "10:00",
  });

  const socialData = [];

  const [gallaryImage, setGallaryImage] = useState([]);
  // const [gallaryTitle, setGallaryTitle] = useState("");

  const handleChange = (e) => {
    const { value, name } = e.target;

    switch (name) {
      case "title":
        setRegistrationData({ ...registrationData, businessName: value });
        const error = validateBusinessDetails({
          ...registrationData,
          businessName: value,
        });
        setError(error);
        break;
      case "website":
        setRegistrationData({ ...registrationData, website: value });
        setError(
          validateBusinessDetails({ ...registrationData, website: value })
        );
        break;

      case "tagline":
        setRegistrationData({ ...registrationData, tagline: value });
        setError(
          validateBusinessDetails({ ...registrationData, tagline: value })
        );
        break;
      case "description":
        setRegistrationData({ ...registrationData, description: value });
        setError(
          validateBusinessDetails({ ...registrationData, description: value })
        );
        break;
      case "business-email":
        setRegistrationData({ ...registrationData, email: value });
        break;
      case "category":
        setRegistrationData({ ...registrationData, category: value });
        setSelectCategory(value);
        setError(
          validateBusinessDetails({ ...registrationData, category: value })
        );
        dispatch(getSubCategoryById(value));
        break;
      case "subcategory":
        setRegistrationData({ ...registrationData, subCategory: value });
        break;
      case "business-phone":
        setRegistrationData({ ...registrationData, phone: value });
        break;
      case "video":
        setRegistrationData({ ...registrationData, video: value });
        break;
      default:
        break;
    }
  };

  const setCompanyData = () => {
    setLoading(true);
    const formData = new FormData();

    for (let key in registrationData) {
      formData.append(key, registrationData[key]);
    }

    for (let key in address) {
      formData.append(key, address[key]);
    }

    formData.append("logo", logo);
    formData.append("coverImage", coverImage);

    for (let i = 0; i < gallaryImage.length; i++) {
      formData.append("media", gallaryImage[i]);
    }

    Object.keys(social).forEach((key) => {
      if (social[key] !== "") {
        socialData.push({
          socialType: key,
          url: social[key],
        });
      }
    });

    formData.append("tags", JSON.stringify(tags));
    formData.append("social", JSON.stringify(socialData));
    formData.append("certified", JSON.stringify(productCheck));
    formData.append("payment", JSON.stringify(paymentCheck));
    formData.append("amenities", JSON.stringify(amenitiesCheck));
    formData.append("businessDays", JSON.stringify(businessDays));
    formData.append("businessTime", JSON.stringify(businessTime));
    formData.append("price", price);

    return formData;
  };

  const handleImage = (e) => {
    const { name } = e.target;

    if (name === "logo") {
      setLogo(e.target.files[0]);
    }
    if (name === "cover") {
      setCoverImage(e.target.files[0]);
    }

    if (name === "gallary") {
      setGallaryImage(e.target.files);
    }
  };

  const handleSubmit = async () => {
    const error = validateBusinessDetails(registrationData);
    if (address.state === "") error.state = "Please Select State";
    if (address.city === "") error.city = "Please Select City";
    setError(error);

    if (Object.keys(error).length) {
      notifyError("Please fill the empty fields");
      window.scroll(0, 0);
      return;
    }
    const data = setCompanyData();
    await dispatch(newCompany(data));
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <Caption title="Add Business">
        <Header />
      </Caption>
      <section className="padding40 " style={{ marginTop: -90 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="submit-job-form">
                <div className="title">
                  <i className="fas fa-align-justify"></i> General information
                </div>
                <div className="form">
                  <div className="input-group">
                    <label>
                      Enter Business Name <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      onChange={handleChange}
                      style={error.businessName && { border: "1px solid red" }}
                    />
                    {error.businessName && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {error.businessName}
                      </label>
                    )}
                  </div>
                  <div className="input-group">
                    <label>Tagline</label>
                    <input
                      type="text"
                      placeholder="Tagline"
                      name="tagline"
                      onChange={handleChange}
                      style={error.tagline && { border: "1px solid red" }}
                    />
                    {error.tagline && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {error.tagline}
                      </label>
                    )}
                  </div>
                  <div className="input-group">
                    <label>
                      Listing Category <span className="red">*</span>
                    </label>
                    <select
                      onChange={handleChange}
                      name="category"
                      style={error.category && { border: "1px solid red" }}
                    >
                      <option>Please select Category</option>
                      {category?.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.businessCategory
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (s) =>
                                  s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(" ")}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>Select Sub Category</label>
                    <select onChange={handleChange} name="subcategory">
                      <option>Please select Category</option>
                      {subCategories?.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.subCategory
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (s) =>
                                  s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(" ")}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>
                      Description <span className="red">*</span>
                    </label>
                    <textarea
                      name="description"
                      onChange={handleChange}
                      style={error.description && { border: "1px solid red" }}
                    ></textarea>
                    {error.description && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {error.description}
                      </label>
                    )}
                  </div>

                  <Amenities
                    amenitiesCheck={amenitiesCheck}
                    setAmenitiesCheck={setAmenitiesCheck}
                  />
                  <PaymentSelect
                    paymentCheck={paymentCheck}
                    setPaymentCheck={setPaymentCheck}
                  />
                  <ProductCertifiedSelect
                    setProductCheck={setProductCheck}
                    productCheck={productCheck}
                  />

                  <div className="input-group">
                    <label>Enter Tags</label>
                    <ReactTagInput
                      tags={tags}
                      onChange={(newTags) => setTags(newTags)}
                    />
                  </div>

                  <div className="input-group">
                    <label>Enter Service</label>
                    <ReactTagInput
                      tags={service}
                      onChange={(newService) => setService(newService)}
                    />
                  </div>

                  <div className="input-group">
                    <label>Enter Product</label>
                    <ReactTagInput
                      tags={product}
                      onChange={(p) => setProduct(p)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="submit-job-form">
                <div className="title">
                  <i className="far fa-images"></i> Media
                </div>
                <div className="form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <label>Logo</label>
                        <div className="file-area">
                          <input
                            type="file"
                            name="logo"
                            id="logo"
                            required="required"
                            onChange={handleImage}
                          />
                          <div className="file-dummy">
                            <div className="success">
                              Great, your files are selected. Keep on.
                            </div>
                            <div className="default">
                              <i className="fas fa-upload"></i>
                              <br />
                              Add Image
                            </div>
                          </div>
                        </div>
                        <p className="small">
                          The image will be shown on listing cards.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <label>Cover Image</label>
                        <div className="file-area">
                          <input
                            type="file"
                            name="cover"
                            id="cover"
                            required="required"
                            onChange={handleImage}
                          />
                          <div className="file-dummy">
                            <div className="success">
                              Great, your files are selected. Keep on.
                            </div>
                            <div className="default">
                              <i className="fas fa-upload"></i>
                              <br />
                              Add Image
                            </div>
                          </div>
                        </div>
                        <p className="small">
                          The image will be shown on listing cards.{" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-group">
                        <label>Gallery Images </label>
                        <div className="file-area">
                          <input
                            type="file"
                            name="gallary"
                            id="gallary"
                            required="required"
                            multiple
                            onChange={handleImage}
                          />
                          <div className="file-dummy">
                            <div className="success">
                              Great, your files are selected. Keep on.
                            </div>
                            <div className="default">
                              <i className="fas fa-upload"></i>
                              <br />
                              Add Image
                            </div>
                          </div>
                        </div>
                        <p className="small">Maximum file size: 64 MB. </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group">
                        <label>Video URL</label>
                        <input
                          type="text"
                          name="video"
                          placeholder="e.g https://www.youtube.com/sanatansetu"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BusinessInformation
              address={address}
              setAddress={setAddress}
              handleChange={handleChange}
              setRegistrationData={setRegistrationData}
              registrationData={registrationData}
              error={error}
              setError={setError}
              validateBusinessDetails={validateBusinessDetails}
            />
            <BusinessSocials social={social} setSocial={setSocial} />
            <BusinessHour
              businessDays={businessDays}
              setBusinessDays={setBusinessDays}
              setBusinessTime={setBusinessTime}
              businessTime={businessTime}
            />
            <div className="col-md-12">
              <div className="submit-job-form">
                <div className="title">
                  <i className="fas fa-dollar-sign"></i> Price Range{" "}
                </div>
                <div className="form">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input-group">
                        <label>Price Range </label>
                        <select onChange={(e) => setPrice(e.target.value)}>
                          <option>Prefer not to say</option>
                          <option value="Cheap">Cheap</option>
                          <option value="Medium">Medium</option>
                          <option value="Premium">Premium</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 submit-job">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Save & Preview
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListBusiness;
