import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { Caption } from "../components/breadcrum/Caption";
import { useForm } from "react-hook-form";
import { notifySuccess, notifyError } from "../utils/toast";
import { apiURL } from "../routes/api";
import axios from "axios";

const Contact = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post(`${apiURL}/api/v1/employer/process-form`, data)
      .then((res) => {
        notifySuccess(res.data.message);
        setValue("email");
        setValue("name");
        setValue("subject");
        setValue("message");
      })
      .catch((e) => {
        notifyError(e.response.data.message);
      });
  };

  console.log(errors);

  return (
    <>
      <Caption title="Contact Us">
        <Header />
      </Caption>
      <section class="padding80 background-4 mb-5 after">
        <div class="container">
          <div class="row contact">
            <div class="col-md-4">
              <div class="box_module">
                <div class="title">
                  <i class="fas fa-info"></i>Contact Info
                </div>
                <div class="info">
                  <ul>
                    {/* <li>
                      <i class="fas fa-map-marker-alt"></i>123 In varius turpis
                      eget turpis
                    </li> */}
                    <li>
                      <i class="fas fa-phone-alt"></i>+91-9310549752
                    </li>
                    <li>
                      <i class="fas fa-envelope"></i>info@sanatansetu.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="box_module">
                <div class="title">
                  <i class="fab fa-telegram-plane"></i>Contact Us
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="form">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingName"
                        placeholder="Your Name"
                        name="name"
                        {...register("name", { required: "Name is required" })}
                      />
                      {errors.name && (
                        <p style={{ color: "red", fontSize: ".7rem" }}>
                          {errors.name.message}
                        </p>
                      )}

                      <label for="floatingInput">
                        Your Name (required){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingEmail"
                        placeholder="Email"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <p style={{ color: "red", fontSize: ".7rem" }}>
                          {errors.email.message}
                        </p>
                      )}

                      <label for="floatingInput">
                        Your Email (required){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingSubject"
                        placeholder="Subject"
                        name="subject"
                        {...register("subject")}
                      />

                      <label for="floatingInput">Subject</label>
                    </div>
                    <div class="form-floating mb-3">
                      <textarea
                        class="form-control"
                        id="floatingMessage"
                        placeholder="Message"
                        name="message"
                        {...register("message", { required: "Enter message" })}
                      ></textarea>
                      {errors.message && (
                        <p style={{ color: "red", fontSize: ".7rem" }}>
                          {errors.message.message}
                        </p>
                      )}
                      <label for="floatingInput">Your Message</label>
                    </div>
                    <div class="mt-3">
                      <input type="submit" value="Send" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
