import React, { useEffect, useState, useRef } from "react";
import Header from "../components/header/Header";
import { useSelector } from "react-redux";
import { ListCard } from "../components/card/ListCard";
import { Loader } from "../components/loader/Loader";
import axios from "axios";
import { apiURL } from "../routes/api";
import { notifyError } from "../utils/toast";
import FilterButton from "../components/filter/FilterButton";
import CategorySection from "../layout/CategorySection";
import Testimonial from "../components/carousel/Testimonial";

const ListingPage = ({ history, location }) => {
  const { company } = useSelector((state) => state.company);
  const [searchData, setSearchData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);
  const [selectedCity, setSelectedCity] = useState("");

  const [filter, setFilter] = useState("");

  const executeScroll = () => {
    searchRef.current.scrollIntoView();
  };

  const search = async (keyword) => {
    let link = `${apiURL}/api/v1/employer/company/search?keyword=${keyword}`;
    if (selectedCity)
      link = `${apiURL}/api/v1/employer/search-by-location?keyword=${keyword}&city=${selectedCity}`;

    try {
      const { data } = await axios.get(link);
      setSearchData(data.searchData);
      if (data.searchData?.length === 0) {
        notifyError("No Search Result found !");
      } else {
        executeScroll();
      }

      setLoading(false);
    } catch (error) {
      notifyError(
        error.response.data ? error.response.data : "Something Went Wrong"
      );
      setLoading(false);
    }
  };

  const [city, setCity] = useState([]);

  const fetchCity = async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    await axios
      .get(`${apiURL}/api/v1/get/all/city`, config)
      .then((res) => setCity(res.data.city))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchCity();
  }, []);

  const featuredListing = company?.filter((item) => item.featured === "true");

  const locationCount = company
    ?.map((item) => {
      return item.city;
    })
    .filter((item, index, arr) => arr.indexOf(item) === index);

  useEffect(() => {
    if (filter === "date") {
      const filterDate = company?.sort(
        (a, b) => b.RegistrationDate - a.RegistrationDate
      );
      setSearchData(filterDate);
    } else if (filter === "0") {
      const filterDate = company?.sort((a, b) => b.price - a.price);
      setSearchData(filterDate);
    } else if (filter === "1") {
      const filterDate = company?.sort((a, b) => a.price - b.price);
      setSearchData(filterDate);
    }
  }, [filter, company]);

  return (
    <>
      {loading && <Loader />}
      <div className="banner-bg">
        <Header />
        <figure className="padding80 mt-5 pb-5">
          <div className="container">
            <div className="row category-banner">
              <div className="col-md-12 text-center mt-5">
                <h2>First Bharatiya Online Business directory</h2>
                <p>
                  Find Sanatan businesses in your local area or anywhere in
                  Bharat.
                </p>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 d-block mx-auto">
                <div className="search-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Phrase or Keywords"
                          onChange={(e) => setKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setLoading(true);
                              search(keyword);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group">
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setSelectedCity(e.target.value);
                          }}
                        >
                          <option selected>Select City</option>
                          {city &&
                            city.map((item) => (
                              <option value={item._id}>
                                {item.cityName
                                  ?.toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() + s.substring(1)
                                  )
                                  .join(" ")}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group">
                        <input
                          type="submit"
                          value="Search Here"
                          onClick={(e) => {
                            setLoading(true);
                            e.preventDefault();
                            search(keyword);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figure>
      </div>
      <section>
        <div className="container">
          <div className="row category-num">
            <div className="col-md-12">
              <div className="box">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div className="d-flex">
                      <div className="icon bg">
                        <i className="fas fa-layer-group"></i>
                      </div>
                      <div className=" mt-3">
                        <h3>{company?.length}</h3>
                        <p>Total Listings</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div className="d-flex">
                      <div className="icon bg2">
                        {" "}
                        <i className="far fa-star"></i>{" "}
                      </div>
                      <div className=" mt-3">
                        <h3>{featuredListing?.length}</h3>
                        <p>Featured Listings</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-sm-0">
                    <div className="d-flex">
                      <div className="icon bg3">
                        <i className="far fa-heart"></i>
                      </div>
                      <div className=" mt-3">
                        <h3>{company?.length}</h3>
                        <p>Top Listings</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 ">
                    <div className="d-flex">
                      <div className="icon bg4">
                        <i className="far fa-map"></i>
                      </div>
                      <div className=" mt-3">
                        <h3>{locationCount?.length}</h3>
                        <p>Locations</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CategorySection />

      {searchData?.length > 0 && (
        <section className="padding80" ref={searchRef}>
          <div className="container">
            <div className="row popular">
              <div className="col-md-12 text-center">
                <h5>
                  {" "}
                  {searchData.length > 0
                    ? `Showing 1 – ${searchData && searchData.length} of ${
                        searchData && searchData.length
                      } results for '${
                        keyword
                          ? keyword
                          : city.find((item) => item._id === selectedCity)
                              ?.cityName
                      }'`
                    : "No Result Found"}
                </h5>
                <p className="mb-5">
                  Browse the best business that match your criteria.Contact them
                  or leave reviews for them.
                  <br />
                  You may refine your search by using the Filter drop-down menu.
                </p>
              </div>
              <FilterButton setFilter={setFilter} />
              <article>
                <div className="container">
                  <div className="row featured">
                    {searchData &&
                      searchData.map((item) => {
                        return (
                          <ListCard
                            key={item.id}
                            id={item.id}
                            name={item.businessName}
                            category={item.category}
                            location={item.location}
                            phone={item.phone}
                            image={item.image && item.image.url}
                            type={item.Status}
                          />
                        );
                      })}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      )}
      {searchData.length === 0 && (
        <section className="padding80">
          <div className="container">
            <div className="row popular">
              <div className="col-md-12 text-center">
                <h2>Popular Businesses </h2>
                <p className="mb-5">
                  Some of our most popular businesses are listed below.<br></br>{" "}
                  Click to learn more about them or to get your company listed
                  here by promoting your business listing in your network.
                </p>
              </div>
              <FilterButton setFilter={setFilter} />
              <article>
                <div className="container">
                  <div className="row featured">
                    {company &&
                      company.map((item) => {
                        return (
                          <ListCard
                            key={item.id}
                            id={item.id}
                            name={item.businessName}
                            category={item.category}
                            location={item.location}
                            phone={item.phone}
                            image={item.image && item.image.url}
                            type={item.Status}
                          />
                        );
                      })}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      )}
      <section className="padding-top background-2">
        <div className="container">
          <div className="row local-businesses">
            <div className="col-md-8">
              <h3>What do you want</h3>
              <h2>
                Discover Great Local
                <br />
                Businesses in Your Area
              </h2>
            </div>
            <div className="col-md-4">
              <div className="content-box text-center">
                <div className="icon">
                  <i className="fas fa-file-invoice"></i>
                </div>
                <div className="num">{company?.length}</div>
                <p className="title">
                  Businesses
                  <br />
                  Listings are on
                  <br />
                  our directory
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <aside className="padding80">
        <div className="container">
          <div className="row customer">
            <div className="col-md-12">
              <h3>What Our Customer Are Saying?</h3>
              <p>
                The biggest reward is to satisfy our clients and share their
                experience with us
              </p>
            </div>
            <Testimonial />
          </div>
        </div>
      </aside>
    </>
  );
};

export default ListingPage;
