import React from "react";
import { useSelector } from "react-redux";
import { CategoryCard } from "../components/card/CategoryCard";

const CategorySection = () => {
  const { activeCategory, categoryCount } = useSelector(
    (state) => state.category
  );
  return (
    <section class="padding80">
      <div class="container">
        <div class="row popular">
          <div class="col-md-12 text-center">
            <h2>Popular Categories</h2>
            <p class="mb-5">
              The below are some of the most popular categories that other
              Sanatanis are looking for.<br></br> Begin your search by clicking one of
              the categories below or by entering a search term in the search
              box above.
            </p>
          </div>
          {activeCategory &&
            activeCategory.map((item, id) => {
              return (
                <CategoryCard
                  key={id}
                  id={item._id}
                  category={item.businessCategory}
                  places={categoryCount[id]}
                  icon={item.icon}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
