import React from "react";
import { Link } from "react-router-dom";



const NewListing = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="text-center">
          <h2>Create a new Listing</h2>
          <div className="icon">
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
            <div className="eltd-separator-icon">
              <i className="fas fa-cog"></i>
            </div>
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
          </div>
          <p>
            This page contains all of your created listings. You can edit your
            existing listings, and see which listings have
            <br />
            been published and which are still pending approval.
          </p>
        </div>
        <div className="account mb-4">
          <div className="use-info">
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/listbusiness" className="button mt-0">
                  Add New Business
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewListing;
