import React from "react";
import { Link } from "react-router-dom";

import moment from "moment";

export const ListCard = ({
  category,
  image,
  name,
  location,
  type,
  id,
  RegistrationDate,
  categoryId,
  children,
}) => {
  return (
    <div className="col-md-3">
      <div className="listing-gird">
        <div className="image">
          <Link
            to={{
              pathname: `/listDetails/${name
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              state: { id },
            }}
          >
            <img
              src={image}
              className="img-fluid"
              style={{ height: 150 }}
              alt={name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://fisnikde.com/wp-content/uploads/2019/01/broken-image.png";
              }}
            />
          </Link>
        </div>
        <div className="meta">
          <span className="open">{type}</span>{" "}
          <span className="category">
            <Link
              to={{
                pathname: `/business/${category
                  ?.split(" ")
                  .join("-")
                  .toLowerCase()}`,
                state: { keyword: categoryId },
              }}
            >
              {category}
            </Link>
          </span>
        </div>
        <h2>
          <Link
            to={{
              pathname: `/listDetails/${name
                .split(" ")
                .join("-")
                .toLowerCase()}`,
              state: { id },
            }}
            style={{ textTransform: "capitalize" }}
          >
            {name}
          </Link>
        </h2>
        <div className="meta-info">
          <span className="location">
            <i className="fas fa-map-marker-alt"></i>{" "}
            {location
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </span>
          {RegistrationDate && (
            <span className="phone">
              <i className="fas fa-calendar-alt"></i>
              {moment(RegistrationDate).format("LL")}
            </span>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
