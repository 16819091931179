import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MyListCard } from "../card/MyListCard";
import { getMyCompanies } from "../../redux/actions/companyAction";

const MyListings = () => {
  const { myBusiness } = useSelector((state) => state.myCompany);
  const dispatch = useDispatch();
  const [toogle, setToogle] = React.useState(false);

  React.useEffect(() => {
    dispatch(getMyCompanies());
    setToogle(false);
  }, [dispatch, toogle]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="text-center">
          <h2>My Listings</h2>
          <div className="icon">
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
            <div className="eltd-separator-icon">
              <i className="fas fa-cog"></i>
            </div>
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
          </div>
          <p>
            This page contains all of your created listings. You can edit your
            existing listings, and see which listings have
            <br />
            been published and which are still pending approval.
          </p>
        </div>

        <div className="account mb-4">
          <div className="use-info">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="pt-0">
                  You currently have{" "}
                  {myBusiness?.length ? myBusiness.length : "0"} listings
                </p>
              </div>

              {!myBusiness?.length ? (
                <div className="col-md-12 text-center">
                  <Link to="/listbusiness" className="button mt-0">
                    Add My Business
                    <i className="fas fa-chevron-right"></i>
                  </Link>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <article>
          <div className="container">
            <div className="row featured">
              {myBusiness &&
                myBusiness.map((item) => (
                  <MyListCard
                    key={item.id}
                    id={item.id}
                    name={item.businessName}
                    category={item.category}
                    location={item.location}
                    phone={item.phone}
                    image={item.image && item.image.url}
                    type={item.Status}
                    RegistrationDate={item.RegistrationDate}
                    setToogle={setToogle}
                  />
                ))}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default MyListings;
