import React from "react";

const BusinessDetails = ({
  businessEmail,
  businessContact,
  businessWebsite,
  businessAddress,
  googleMap,
}) => {
  return (
    <>
      <div className="box2 mt-4">
        <div className="title">
          <i className="far fa-bookmark"></i> Business Info
        </div>
        <div className="content">
          <div className="business-info">
            <ul>
              {businessEmail && (
                <li>
                  <i className="far fa-envelope"></i>
                  <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
                </li>
              )}
              {businessContact && (
                <li>
                  <i className="fas fa-phone-alt"></i>
                  <a href={`tel:${businessContact}`}>+91-{businessContact}</a>
                </li>
              )}
              {businessWebsite && (
                <li>
                  <i className="fas fa-globe-europe"></i>
                  <a href={businessWebsite} target="_blank" rel="noreferrer">
                    {businessWebsite}
                  </a>
                </li>
              )}
              {businessAddress && (
                <li>
                  <i className="fas fa-map-marker-alt"></i>
                  {businessAddress}
                  <br></br>
                  {googleMap && (
                    <a
                      href={googleMap}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        fontWeight: "bolder",
                        textDecoration: "underline",
                      }}
                    >
                      (View Google Map)
                    </a>
                  )}
                </li>
              )}
            </ul>
            {/* <div className="inbox">
              <a href="#">
                <i className="far fa-envelope"></i> Inbox
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessDetails;
