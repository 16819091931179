import React, { useState } from "react";
import { apiURL, config } from "../../routes/api";
import axios from "axios";
import { notifyError, notifySuccess } from "../../utils/toast";
import { Loader } from "../../components/loader/Loader";

const UpdatePassword = () => {
  const [update, setUpdate] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const updatePassword = async (data) => {
    try {
      const res = await axios.put(
        `${apiURL}/api/v1/password/update`,
        data,
        config
      );
      setLoading(false);
      if (res.data.user) {
        notifySuccess("Password Changed");
        setUpdate({ oldPassword: "", newPassword: "", confirmPassword: "" });
        return;
      }
      notifySuccess(res.data.message);
    } catch (error) {
      notifyError(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <a
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Update Password
      </a>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Update your Password</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Enter Old Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                value={update.oldPassword}
                onChange={(e) =>
                  setUpdate({ ...update, oldPassword: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Enter New Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                value={update.newPassword}
                onChange={(e) =>
                  setUpdate({ ...update, newPassword: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                value={update.confirmPassword}
                onChange={(e) =>
                  setUpdate({ ...update, confirmPassword: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-3">
            <div className="input-group">
              <input
                type="button"
                className="btn btn-success"
                onClick={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  updatePassword(update);
                }}
                value="Update Password"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
