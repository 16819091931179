import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myReview } from "../../redux/actions/companyAction";
import SingleReview from "./SingleReview";

const UserReview = ({ companyId, companyName }) => {
  const { myreview: reviews } = useSelector((state) => state.myReview);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myReview(companyId));
  }, [dispatch, companyId]);

  if (!reviews) return <div></div>;
  return (
    <>
      <div class="review">
        <div class="title-box">
          <span>{reviews?.length} Reviews </span>For {companyName}
        </div>
        {reviews?.map((review) => (
          <SingleReview review={review} />
        ))}
      </div>
    </>
  );
};

export default UserReview;
