import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ListCard } from "../card/ListCard";
import { notifyError } from "../../utils/toast";
import { apiURL, config } from "../../routes/api";
import { Link } from "react-router-dom";
import { getFavourite } from "../../redux/actions/companyAction";

const Wishlist = () => {
  const { fav } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [toogle, setToogle] = useState(false);
  const removeBusiness = async (id) => {
    let url = `${apiURL}/api/v1/employer/remove/fav?id=${id}`;
    await axios
      .delete(url, config)
      .then((res) => {
        notifyError(res.data.message);
        setToogle(true);
      })
      .catch((e) => notifyError(e.response.message));
  };

  useEffect(() => {
    dispatch(getFavourite());
    setToogle(false);
  }, [dispatch, toogle]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="text-center">
          <h2>My Wishlist</h2>
          <div className="icon">
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
            <div className="eltd-separator-icon">
              <i className="fas fa-cog"></i>
            </div>
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
          </div>
          {fav?.length === 0 && <p>Your Wishlist is Empty.</p>}
        </div>
        {fav?.length === 0 && (
          <div className="account mb-4">
            <div className="use-info">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p className="pt-0">
                    Browse businesses and start adding them as in your Wishlist.
                  </p>
                </div>
                <div className="col-md-12 text-center">
                  <Link to="/listingPage" className="button mt-0">
                    Explore Businesses
                    <i className="fas fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <article>
          <div className="container">
            <div className="row featured">
              {fav &&
                fav.map((item) => (
                  <>
                    <ListCard
                      key={item.id}
                      id={item.id}
                      name={item.businessName}
                      category={item.category}
                      location={item.location}
                      phone={item.phone}
                      image={item.image && item.image.url}
                      type={item.Status}
                      RegistrationDate={item.RegistrationDate}
                    >
                      <button
                        type="button"
                        class="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#"
                        onClick={() => {
                          removeBusiness(item.id);
                        }}
                      >
                        Remove from Wishlist
                      </button>
                    </ListCard>
                  </>
                ))}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Wishlist;
