import React from "react";
import { Link } from "react-router-dom";

const BusinessSocials = ({ social, url }) => {
  return (
    <>
      <li>
        <Link to={{ pathname: `${url}` }} target="_blank" >
          <i class={`fab fa-${social}`}></i>
        </Link>
      </li>
    </>
  );
};

export default BusinessSocials;
