import React from "react";

const list = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const BusinessHour = ({ dh }) => {
  const startTime = parseInt(dh?.time?.start);
  const endTime = parseInt(dh?.time?.end);
  return (
    <>
      <div className="box2 mt-4">
        <div className="title">
          <i className="far fa-bookmark"></i> Business Hour
        </div>
        <div className="content">
          <div className="business-info">
            <ul>
              {dh?.days?.map((day, i) => (
                <div
                  key={i}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <li style={{ color: "black" }}>
                    <i className={`	fas fa-calendar-alt`}></i>
                    {day}
                  </li>
                  {day !== "24x7" && (
                    <span>
                      <li style={{ color: "black" }}>
                        {/* <i className={`	fas fa-clock`}></i> */}
                        {startTime < 12
                          ? dh?.time?.start + " AM"
                          : dh?.time?.start + "PM"}{" "}
                        -{" "}
                        {+endTime < 12
                          ? dh?.time?.end + " AM"
                          : dh?.time?.end + "PM"}
                      </li>
                    </span>
                  )}
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessHour;
