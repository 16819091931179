import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Caption } from "../components/breadcrum/Caption";
import Header from "../components/header/Header";
import { notifyError, notifySuccess } from "../utils/toast";
import { apiURL, config } from "../routes/api";
import axios from "axios";
import { Loader } from "../components/loader/Loader";
import BusinessDetails from "../components/business/BusinessDetails";
import {
  getcompanyDetails,
  myReview,
  getFavourite,
} from "../redux/actions/companyAction";
import BusinessSocials from "../components/business/BusinessSocials";
import BusinessGallary from "../components/carousel/BusinessGallary";
import EnquiryForm from "../components/form/EnquiryForm";
import ReviewSection from "../components/review/ReviewSection";
import VideoURL from "../components/video/VideoURL";
import GMap from "../components/googlemap/GMap";
import BusinessHour from "../components/business/BusinessHour";
import UserReview from "../components/review/UserReview";

export const ListingDetail = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = location.state;
  const { company } = useSelector((state) => state.companyDetail);
  // const { user, isAuthenticated } = useSelector((state) => state.user);
  // const { myreview } = useSelector((state) => state.myReview);
  const [loading, setLoading] = useState(false);

  const businessRef = useRef();

  const { fav } = useSelector((state) => state.wishlist);

  const [toogle, setToogle] = useState(false);

  useEffect(() => {
    const isWishlist = fav?.find((item) => item.id === id);
    if (isWishlist) {
      setToogle(true);
    } else {
      setToogle(false);
    }
  }, [fav, id]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    dispatch(getcompanyDetails(id));
    setLoading(false);
  }, [location, dispatch, id]);

  useEffect(() => {
    dispatch(getFavourite());
  }, [dispatch]);

  const addToFav = async (id, name) => {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    await axios
      .post(
        `${apiURL}/api/v1/employer/add/fav?id=${id}`,
        {
          businessName: name,
        },
        config
      )
      .then((res) => {
        if (res.data.toogle === "remove") {
          notifyError(res.data.message);
          // localStorage.removeItem(company._id);
          setToogle(false);
          return;
        }
        if (res.data.toogle === "add") {
          notifySuccess(res.data.message);
          // localStorage.setItem(res.data.fav.company, res.data.fav.company);
          setToogle(true);
        }
      })
      .catch((e) => {
        notifyError(e.response.data.message);
        history.push(`/login`);
      });
  };

  const Payment = company?.payment?.length > 0 ? company?.payment : [];
  const Certified = company?.certified?.length > 0 ? company?.certified : [];
  const Amenities = company?.Amenities?.length > 0 ? company?.Amenities : [];
  const bt = Array.isArray(company?.businessHour)
    ? null
    : company?.businessHour;

  return (
    <>
      {loading && <Loader />}
      <Caption
        title={company?.businessName
          ?.toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")}
      >
        <Header />
      </Caption>
      <>
        <section className="padding40" style={{ marginTop: -90 }}>
          <div className="container">
            <div className="row detail">
              <div className="col-md-12">
                <div className="box margin-130">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="business-logo">
                            <img
                              src={company?.logo?.url}
                              className="img-fluid"
                              alt={company?.name}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "https://fisnikde.com/wp-content/uploads/2019/01/broken-image.png";
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="categories">
                            {company?.categoryName?.businessCategory
                              ?.toLowerCase()
                              .split(" ")
                              .map(
                                (s) =>
                                  s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(" ")}
                          </div>
                          <h1>
                            {company?.businessName
                              ?.toLowerCase()
                              .split(" ")
                              .map(
                                (s) =>
                                  s.charAt(0).toUpperCase() + s.substring(1)
                              )
                              .join(" ")}
                            <i className="fas fa-check-circle"></i>
                          </h1>
                          <p className="categories">{company?.tagline}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end">
                      <p className="favorite">
                        <Link
                          onClick={() =>
                            addToFav(company._id, company.businessName)
                          }
                        >
                          <i className="far fa-star"></i>{" "}
                          {toogle ? "Remove from Wishlist" : "Add to wishlist"}
                        </Link>
                      </p>
                      <div className="row">
                        <div className="col-md-5 text-end">
                          <p className="price">
                            Price range
                            <br />
                            {company?.priceRange}
                          </p>
                        </div>
                        <div className="col-md-7">
                          <button
                            className="message"
                            onClick={() => {
                              businessRef.current.scrollIntoView();
                            }}
                          >
                            <i className="far fa-comments"></i> Show Business
                            Details
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      {company?.gallary?.images && (
                        <BusinessGallary data={company?.gallary?.images} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className=" " ref={businessRef}>
          <div className="container">
            <div className="row detail">
              <div className="col-md-8">
                <div className="box">
                  <div className="row">
                    <div className="col-md-12 content">
                      <h2>Business Description</h2>
                      <p>{company?.description}</p>
                    </div>
                    {Amenities.length > 0 && (
                      <div className="col-md-12">
                        <h4>Amenities</h4>
                        <div className="list-1 mt-3">
                          <ul>
                            {Amenities.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {Payment.length > 0 && (
                      <div class="col-md-12 mt-4">
                        <h4>Payment</h4>
                        <div class="list-1 mt-3">
                          <ul>
                            {Payment.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {Certified.length > 0 && (
                      <div class="col-md-12 mt-4">
                        <h4>Product Certified</h4>
                        <div className="list-1 mt-3">
                          <ul>
                            {Certified.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {company?.tags?.length > 0 && (
                      <div className="col-md-12 mt-4">
                        <h4>Tags</h4>
                        <div className="list-2 mt-3">
                          <ul>
                            {company?.tags?.map((item) => (
                              <li key={item}>
                                <a href="/">{item}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    {company?.gallary?.video && (
                      <VideoURL data={company?.gallary?.video} />
                    )}

                    {/* {company?.address?.googlemap && (
                      <GMap
                        url={`https://www.google.com/maps/embed?pb=${
                          company?.address?.googlemap?.split("=")[1]
                        }`}
                      />
                    )} */}
                  </div>
                </div>
                <UserReview
                  companyId={location.state.id}
                  companyName={company?.businessName
                    ?.toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")}
                />
                <ReviewSection companyId={location.state.id} />
              </div>
              <div className="col-md-4 right-side">
                <EnquiryForm companyId={location.state.id} />
                {!company && (
                  <div className="box mt-5">
                    <div className="heading">Claim Listing</div>
                    <div className="claim">
                      <p>Is this your business?</p>
                      <p>
                        Claim listing is the best way to manage and protect your
                        business.
                      </p>
                      <p>
                        <Link to="/" className="button">
                          Claim This Listing
                        </Link>
                      </p>
                    </div>
                  </div>
                )}

                <BusinessDetails
                  googleMap={company?.address?.googlemap}
                  businessEmail={company?.email}
                  businessContact={company?.phone}
                  businessWebsite={company?.website}
                  businessAddress={`${
                    company?.address?.add
                  }, ${company?.city?.cityName
                    ?.toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")}, ${company?.state?.stateName
                    ?.toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")} (${company?.address?.pincode})`}
                />

                {company?.social?.length > 0 && (
                  <div class="box2">
                    <div class="title">
                      <i class="far fa-bookmark"></i> Follow Us
                    </div>
                    <div class="content">
                      <div class="social">
                        <ul>
                          {company?.social?.map((item) => (
                            <BusinessSocials
                              url={item.url}
                              social={item.socialType}
                              key={item.url}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {bt && <BusinessHour dh={bt} />}
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
