import React from "react";
import Carousel from "./Carousel";

const Testimonial = () => {
  return (
    <div className="col-md-12">
      <Carousel>
        <div className="owl-stage-outer">
          <div className="owl-stage">
            <div className="owl-item">
              <div className="item">
                <p>
                  <i className="fas fa-quote-left"></i>
                </p>
                <h4>Great Platform To Start With</h4>
                <p className="pb-4">
                  Thanks guys for running a successful directory business! I
                  strongly recommend listing your business in Sanatan Setu to
                  everyone interested in! It is really worth your time and money
                  as the exposure you get is amazing.
                </p>
                <div className="float-start">
                  <img
                    src="https://www.webnetcreatives.com/sanatansetu/assets/images/testimonial-profile-2.png"
                    className="profile"
                    alt="test"
                  />
                </div>
                <div className="float-start">
                  <h5>Prajapati Thakur</h5>
                  <p className="small">Barber/Saloon Owner</p>
                </div>
              </div>
            </div>
            <div className="owl-item">
              <div className="item">
                <p>
                  <i className="fas fa-quote-left"></i>
                </p>
                <h4>Very Effective For Our Business</h4>
                <p className="pb-4">
                  We always wanted to deal with Sanatani businesses and Sanatan
                  Setu has come at the right time. Now we are sure who we are
                  dealing with and thus work confidently.I will tell all others
                  to list here as well.
                </p>
                <div className="float-start">
                  <img
                    src="https://www.webnetcreatives.com/sanatansetu/assets/images/indian-testimonial-profile.png"
                    className="profile"
                    alt="est"
                  />
                </div>
                <div className="float-start">
                  <h5>Amrita Singh</h5>
                  <p className="small">Flour Mills</p>
                </div>
              </div>
            </div>

            <div className="owl-item">
              <div className="item">
                <p>
                  <i className="fas fa-quote-left"></i>
                </p>
                <h4>Very Effective For Our Business</h4>
                <p className="pb-4">
                  We are seeing good results and that is why we're loving
                  Sanatan Setu. It's easy to manage and operate. Sanatan Setu is
                  a good option for all the businesses to get more exposure and
                  growth.
                </p>
                <div className="float-start">
                  <img
                    src="https://www.webnetcreatives.com/sanatansetu/assets/images/customer-testimonial-lady.png"
                    className="profile"
                    alt="test"
                  />
                </div>
                <div className="float-start">
                  <h5>Sanjukta Saini</h5>
                  <p className="small">Bakery Shop</p>
                </div>
              </div>
            </div>

            <div className="owl-item">
              <div className="item">
                <p>
                  <i className="fas fa-quote-left"></i>
                </p>
                <h4>Improved Our Online Exposure</h4>
                <p className="pb-4">
                  Sanatan Setu helped us on multiple levels. After adding our
                  business, we started receiving enquiries from customers in our
                  area which didn't know we existed. I'm telling everyone I know
                  to list their business on Sanatan Setu.
                </p>
                <div className="float-start">
                  <img
                    src="https://www.webnetcreatives.com/sanatansetu/assets/images/male-testimonial-profile.png"
                    className="profile"
                    alt="rest"
                  />
                </div>
                <div className="float-start">
                  <h5>Rajesh Lakhara</h5>
                  <p className="small">Bookstore</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonial;
