import React from "react";
import { Link } from "react-router-dom";

const ProfileTab = ({ email, signOutUser, name }) => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="my-profile"
        role="tabpanel"
        aria-labelledby="my-profile-tab"
      >
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="text-center">
              <h2>My Profile</h2>
              <div className="icon">
                <div className="eltd-separator">
                  <span style={{ width: 85, height: 1 }}></span>
                </div>
                <div className="eltd-separator-icon">
                  <i className="fas fa-cog"></i>
                </div>
                <div className="eltd-separator">
                  <span style={{ width: 85, height: 1 }}></span>
                </div>
              </div>
              <p>
                Welcome to your profile page. This is a brief overview of your
                user profile. You can change
                <br />
                the information below by visiting the “Edit Profile” page.
              </p>
            </div>
            <div className="account mb-4">
              <h5>Hi, {name}</h5>
              <div className="use-info">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src="assets/images/use.jpg"
                      className="profile"
                      alt="user"
                    />
                  </div>
                  <div className="col-md-7">
                    <p>
                      You are currently signed in as{" "}
                      <Link href="#">{email}</Link>
                    </p>
                  </div>
                  <div className="col-md-3 text-center">
                    <Link onClick={() => signOutUser()} className="button">
                      Sign out <i className="fas fa-chevron-right"></i>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTab;
