import React, { useEffect, useState } from "react";
import { apiURL } from "../../routes/api";
import axios from "axios";

const BusinessInformation = ({
  setAddress,
  address,
  handleChange,
  setRegistrationData,
  registrationData,
  error,
  setError,
  validateBusinessDetails,
}) => {
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const fetchState = async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    await axios
      .get(`${apiURL}/api/v1/get/all/state`, config)
      .then((res) => {
        setState(res.data.state);
      })
      .catch((e) => console.log(e));
  };

  const fetchCity = async (id) => {
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    };
    await axios
      .get(`${apiURL}/api/v1/get/city?id=${id}`, config)
      .then((res) => setCity(res.data.city))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchState();
  }, []);

  useEffect(() => {
    fetchCity(address?.state);
  }, [address.state]);

  const handleState = (e) => {
    const { value } = e.target;

    setAddress({ ...address, state: value });
    fetchCity(value);
    // ValidateAddressDetails(value)
  };

  const handleCity = (e) => {
    setAddress({ ...address, city: e.target.value });
  };

  return (
    <div className="col-md-12">
      <div className="submit-job-form">
        <div className="title">
          <i className="fas fa-headphones"></i> Business information{" "}
        </div>
        <div className="form">
          <div className="row">
            <div className="col-md-12">
              <div className="input-group">
                <label>Listing Region </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-group">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="e.g 34 Wigmore Street"
                  value={address?.add}
                  onChange={(e) =>
                    setAddress({ ...address, add: e.target.value })
                  }
                />
              </div>
              <div className="input-group">
                <label>
                  State<span className="red">*</span>
                </label>
                <select
                  onChange={handleState}
                  style={error.state && { border: "1px solid red" }}
                >
                  <option>Please select State</option>
                  {state &&
                    state.map((item) => (
                      <option
                        value={item._id}
                        key={item._id}
                        selected={item._id === address.state ? true : false}
                      >
                        {item.stateName
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (s) => s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" ")}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-group">
                <label>
                  City<span className="red">*</span>
                </label>
                <select
                  onChange={handleCity}
                  style={error.city && { border: "1px solid red" }}
                >
                  <option>Please select State First</option>
                  {city &&
                    city.map((item) => (
                      <option
                        value={item._id}
                        key={item._id}
                        selected={item._id === address.city ? true : false}
                      >
                        {item.cityName
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (s) => s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" ")}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-group">
                <label>Pin</label>
                <input
                  type="text"
                  value={address.pincode}
                  maxLength={6}
                  placeholder="e.g 110001"
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      pincode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-group">
                <label>Google Map URL</label>
                <input
                  type="text"
                  value={address.googleMap}
                  placeholder="Enter Google Map URL"
                  onChange={(e) =>
                    setAddress({ ...address, googleMap: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="input-group">
              <label>Website</label>
              <input
                type="text"
                name="website"
                value={registrationData.website}
                placeholder="e.g yourwebsite.com"
                onChange={handleChange}
                // style={error.website && { border: "1px solid red" }}
              />
              {/* {error.website && (
                      <label style={{ color: "red", fontSize: ".7rem" }}>
                        {error.website}
                      </label>
                    )} */}
            </div>

            <div className="input-group">
              <label>
                Phone<span className="red">*</span>
              </label>
              <input
                type="text"
                maxLength={10}
                value={registrationData.phone}
                placeholder="Enter Mobile Number"
                onChange={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    phone: e.target.value,
                  });
                  setError(
                    validateBusinessDetails({
                      ...registrationData,
                      phone: e.target.value,
                    })
                  );
                }}
                style={error.phone && { border: "1px solid red" }}
              />
              {error.phone && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {error.phone}
                </label>
              )}
            </div>
            <div className="input-group">
              <label>
                Email<span className="red">*</span>
              </label>
              <input
                type="text"
                value={registrationData.email}
                placeholder="e.g youremail@email.com"
                onChange={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    email: e.target.value,
                  });

                  setError(
                    validateBusinessDetails({
                      ...registrationData,
                      email: e.target.value,
                    })
                  );
                }}
                style={error.email && { border: "1px solid red" }}
              />
              {error.email && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {error.email}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
