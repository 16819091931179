import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import { Caption } from "../components/breadcrum/Caption";
import AuthContainer from "../components/auth/AuthContainer";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register, logout, clearErrors } from "../redux/actions/userAction";
import { notifyError, notifySuccess } from "../utils/toast";
import GoogleAuth from "../utils/GoogleAuth";
import axios from "axios";
import { apiURL, config } from "../routes/api";

import { validateRegisterDetails } from "../utils/Validator";
import { Loader } from "../components/loader/Loader";

const Register = ({ history }) => {
  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState({});
  const { user, isAuthenticated, error } = useSelector((state) => state.user);
  const [dissableInput, setDisableInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const [OTP, setOTP] = useState("");

  const [disableButton, setDisableButton] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const [checked, setChecked] = useState(false);

  const [emailAvailable, setEmailAvailable] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
      notifySuccess("Register Successfully");
    }

    if (error === "Please Login to access this resource") {
      return;
    } else {
      if (error === "Duplicate email Entered") {
        return notifyError("An account with this email already exists. ");
      }
      notifyError(error);
      dispatch(clearErrors());
    }
  }, [isAuthenticated, error, history, dispatch]);

  const [registrationData, setRegistrationData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role: "",
    avatar: [],
    checked: false,
  });

  const checkEmail = (value) => {
    var url = `${apiURL}/api/v1/check-email?email=${value}`;
    fetch(url)
      .then((result) => result.json())
      .then((resdata) => {
        setEmailAvailable(resdata);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) setRegistrationData({ ...registrationData, avatar: files[0] });

    switch (name) {
      case "username":
        setRegistrationData({ ...registrationData, name: value });
        setValidationError(
          validateRegisterDetails({ ...registrationData, name: value })
        );
        break;
      case "email":
        setRegistrationData({ ...registrationData, email: value });
        checkEmail(value);
        setValidationError(
          validateRegisterDetails({ ...registrationData, email: value })
        );
        break;
      case "phone":
        setRegistrationData({ ...registrationData, phone: value });
        setValidationError(
          validateRegisterDetails({ ...registrationData, phone: value })
        );
        break;
      case "password":
        setRegistrationData({ ...registrationData, password: value });
        setValidationError(
          validateRegisterDetails({ ...registrationData, password: value })
        );
        break;
      case "confirm-password":
        setRegistrationData({ ...registrationData, confirmPassword: value });
        setValidationError(
          validateRegisterDetails({
            ...registrationData,
            confirmPassword: value,
          })
        );
        break;
      case "user-type":
        setRegistrationData({ ...registrationData, role: value });
        break;
      default:
        break;
    }
  };

  const registerRef = React.useRef(null);

  // registerRef.current.innerText = "Disable"

  // console.log(registerRef);

  const handleRegistration = () => {
    const e = validateRegisterDetails(registrationData);
    setValidationError(e);
    if (!isVerified) {
      notifyError("Verify Mobile !");
      return;
    }
    if (Object.keys(e).length) return;
    if (!emailAvailable.success) return;
    const formData = new FormData();
    formData.append("name", registrationData.name);
    formData.append("email", registrationData.email);
    formData.append("password", registrationData.password);
    formData.append("phone", registrationData.phone);
    if (registrationData.role === "employer")
      formData.append("role", registrationData.role);
    dispatch(register(formData));
  };
  // console.log(checked);

  const sendOTP = async (phone) => {
    const e = validateRegisterDetails(registrationData);
    // console.log(e);
    notifyError(e.phone);
    if (e.phone) return;
    setLoading(true);
    try {
      const res = await axios.post(
        `${apiURL}/api/v1/otp/send`,
        { phone },
        config
      );
      if (res.data) {
        notifySuccess(res.data.message);
        setDisableButton(true);
        setLoading(false);
        setDisableInput(true);
      }
    } catch (error) {
      // console.log(error);
      notifyError(error.response.data.message);
      setLoading(false);
    }
  };

  const verifyOTP = async (phone, otp) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${apiURL}/api/v1/otp/verify`,
        { phone, otp },
        config
      );
      if (res.data) {
        notifySuccess(res.data.message);
        setDisableButton(false);
        setVerified(true);
        setLoading(false);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Caption title="Register">
        <Header />
      </Caption>
      <AuthContainer>
        <div className="col-md-4 mt-5" id="signup">
          <div className="title">Sign up</div>
          <div className="form">
            <div className="input-group">
              <input
                type="text"
                placeholder="Full Name"
                name="username"
                onChange={handleChange}
              />
              {validationError.name && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {validationError.name}
                </label>
              )}
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Email"
                name="email"
                onChange={handleChange}
              />

              {validationError.email ? (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {validationError.email}
                </label>
              ) : emailAvailable?.success ? (
                <label style={{ color: "green", fontSize: ".7rem" }}>
                  {emailAvailable.message}
                </label>
              ) : (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {emailAvailable.message}
                </label>
              )}
            </div>
            <div className="input-group" style={{ display: "block" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Mobile No."
                    name="phone"
                    maxLength={10}
                    onChange={handleChange}
                    disabled={dissableInput ? true : false}
                  />
                  {validationError.phone && (
                    <label style={{ color: "red", fontSize: ".7rem" }}>
                      {validationError.phone}
                    </label>
                  )}
                </div>
                {!disableButton ? (
                  <div className="col-md-4 text-end">
                    {isVerified ? (
                      <i
                        className="fas fa-check-circle"
                        style={{ color: "green", fontSize: 16 }}
                      ></i>
                    ) : (
                      <button
                        onClick={() => sendOTP(registrationData.phone)}
                        className="verify"
                        style={{ fontSize: 12 }}
                      >
                        Send OTP
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="col-md-6 text-end">
                    <button
                      onClick={() => {
                        setDisableButton(false);
                        // setVerified(true)
                        setDisableInput(false);
                      }}
                      style={{ fontSize: 12 }}
                      className="verify"
                    >
                      Edit Phone
                    </button>
                  </div>
                )}
              </div>
            </div>
            {disableButton && (
              <div className="input-group" id="otp">
                <div className="col-md-6">
                  <input
                    type="text"
                    maxLength={6}
                    placeholder="Enter OTP"
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </div>
                <div className="col-md-3 text-end">
                  <button
                    onClick={() => verifyOTP(registrationData.phone, OTP)}
                    className="verify"
                    style={{ fontSize: 12 }}
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}

            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
              {validationError.password && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {validationError.password}
                </label>
              )}
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder="Repeat Password"
                name="confirm-password"
                onChange={handleChange}
              />
              {validationError.confirmPassword && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {validationError.confirmPassword}
                </label>
              )}
            </div>
            <div className="form-check col-md-12">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={checked}
                id="defaultCheck1"
                onChange={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    checked: e.target.checked,
                  });
                  validateRegisterDetails({ checked: e.target.checked });
                }}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {" "}
                I agree to the <a href="#">Privacy Policy</a> &{" "}
                <a href="#">Terms of Services </a>
              </label>
              {validationError.checked && (
                <label style={{ color: "red", fontSize: ".7rem" }}>
                  {validationError.checked}
                </label>
              )}
            </div>
            <div className="border-1"></div>
            <div className="input-group text-center">
              <button
                ref={registerRef}
                // disabled
                onClick={(e) => {
                  e.preventDefault();
                  handleRegistration();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleRegistration();
                  }
                }}
              >
                Register
              </button>
            </div>

            <div className="action-holder">
              <p>Have an account? Log in?</p>
              <Link to="/login">Log in</Link>{" "}
            </div>
            <div className="social-login">
              <p>Connect with Social Networks</p>
              <GoogleAuth />
            </div>
          </div>
        </div>
      </AuthContainer>
    </>
  );
};

export default Register;
