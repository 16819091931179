import React from "react";

const data = [
  {
    name: "Dharmik Certified",
  },
  {
    name: "Jhatka Certified",
  },
  {
    name: "ISO 9001",
  },
  {
    name: "ISO 9000:2015",
  },
  {
    name: "ISO 9001:2015",
  },
  {
    name: "ISO 9008:2015",
  },
];

const ProductCertifiedSelect = ({ setProductCheck, productCheck }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setProductCheck((pre) => [...pre, name]);
    } else {
      setProductCheck(productCheck.filter((item) => item !== name))
    }
  };
  return (
    <>
      <label style={{ paddingBottom: 20 }}>My Product Are Certified</label>
      <br></br>
      <div className="input-group">
        {data.map((item) => (
          <div className="form-check form-check-inline" key={item.name}>
            <input
              className="form-check-input"
              type="checkbox"
              id={item.name}
              value={item.name}
              name={item.name}
              checked={
                productCheck.filter((ne) => ne === item.name).length
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={item.name}>
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductCertifiedSelect;
