import React from "react";

const VideoURL = ({ data }) => {
  return (
    <div className="col-md-12 mt-4">
      <iframe
        width="100%"
        height="400"
        src={`https://www.youtube.com/embed/${data?.split("=")[1]}`}
        title="youtube video url"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoURL;
