import React from "react";

import video from "./homeVideo.mp4";

const HomeVideo = () => {
  return (
    <video
      style={{
        position: "absolute",
        overflow: "hidden",
        width: "100%",
        objectFit: "cover",
      }}
      autoPlay
      loop
      muted
    >
      <source src={video} type="video/mp4" />
    </video>
  );
};

export default HomeVideo;
