import React from "react";
import { apiURL } from "../../routes/api";

export default class Autocompletemain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestion: [],
      text: "",
      id: "",
    };
    this.heightInput = React.createRef();
  }

  ontextchange = (event) => {
    const svalue = event.target.value;
    this.props.setKeyword(svalue);
    this.state.text = event.target.value;
    if (svalue.length == 0) this.setState({ suggestion: [], text: "" });
    if (svalue.length > 0) {
      var url = `${apiURL}/api/v1/employer/random-search?keyword=${svalue}`;
      fetch(url)
        .then((result) => result.json())
        .then((resdata) => {
          this.setState({ suggestion: resdata.searchData });
        });
    }
  };

  suggSeleted(value, id) {
    this.setState(() => ({
      text: value,
      suggestion: [],
      id: id,
    }));
    // this.props.setKeyword(value);
    // console.log(value);
    this.props.history.push({
      pathname: `/listDetails/${value.split(" ").join("-").toLowerCase()}`,
      state: { id },
    });
    // console.log(this.heightInput);
  }

  rendersuggs = () => {
    const mapdata = this.state.suggestion;
    if (mapdata?.length === 0) {
      return null;
    }
    return (
      <ul
        style={{
          position: "absolute",
          width: "110%",
          background: "white",
          color: "black",
          marginTop: 10,
          borderRadius: 10,
          boxShadow: "2px 6px 10px 2px rgba(0,0,0,0.37)",
          overflowY: "auto",
          maxHeight: 300,
        }}
      >
        {mapdata?.map((item) => (
          <li
            onClick={() => this.suggSeleted(item.businessName, item.id)}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              borderRadius: 10,
              textDecoration: "none",
              listStyle: "none",
            }}
            ref={this.heightInput}
          >
            <span style={{ textTransform: "capitalize" }}>
              {item.businessName}
            </span>
            ,{" "}
            <span
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: "lighter",
              }}
            >
              {item.random.split(",")[0]}
            </span>
            <span style={{ color: "gray", display: "block", fontSize: 12 }}>
              {item.random.split(",")[1]}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <input
          onChange={this.ontextchange}
          type="text"
          placeholder="What are you looking for?"
          value={this.state.text}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (this.state.text) {
                this.props.history.push(`/business/${this.state.text}`);
              }
            }
          }}
        />
        {this.rendersuggs()}
      </div>
    );
  }
}
