import React from "react";

const FilterButton = ({ setFilter }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  return (
    <div className="d-flex flex-row-reverse">
      <div className="col-md-2">
        <div className="input-group">
          <select className="form-select" onChange={handleChange}>
            <option selected>Filter</option>
            <option value="0">Low to High</option>
            <option value="1">High to Low</option>
            <option value="date">Date Published</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
