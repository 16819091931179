import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Autocompletemain from "../form/AutoComplete";
import Header from "../header/Header";
import { Loader } from "../loader/Loader";
import Typewriter from "typewriter-effect";
import HomeVideo from "../video/HomeVideo";

const HomeBanner = () => {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  let data = ["Restaurants", "Hotels", "Shops", "fitness", "Shopping"];

  const history = useHistory();
  return (
    <>
      {loading && <Loader />}
      <div>
        <Header />
        <HomeVideo />
        <figure className="padding80 mt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-7 banner" style={{ color: "black" }}>
                <h1 id="check">
                  Search local sanatan businesses and{" "}
                  <div className="d-flex">
                    <Typewriter
                      options={{
                        strings: data,
                        autoStart: true,
                        loop: true,
                      }}
                    />{" "}
                    <span>here</span>
                  </div>
                </h1>
                <p>
                  Bharat’s First Online business directory exclusively run and
                  managed by Sanatanis.
                </p>
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="input-group">
                        <div className="col-md-9">
                          <Autocompletemain
                            history={history}
                            setKeyword={setKeyword}
                          />
                        </div>

                        <div className="col-md-3">
                          <button
                            type="submit"
                            onClick={() => {
                              if (keyword) {
                                history.push(`/business/${keyword}`);
                              }
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figure>
      </div>
    </>
  );
};

export default HomeBanner;
