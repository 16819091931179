import React, { useEffect } from "react";
import HomeBanner from "../components/banner/HomeBanner";
import { CategoryCard } from "../components/card/CategoryCard";
import { ListCard } from "../components/card/ListCard";
import { ListingData } from "../utils/listingData";
import { CategoryData } from "../utils/categoryData";
import { JobData } from "../utils/jobListingData";
import { JobCard } from "../components/card/JobCard";
import Carousel from "../components/carousel/Carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Testimonial from "../components/carousel/Testimonial";

export const Home = () => {
  const { company } = useSelector((state) => state.company);
  const { activeCategory, categoryCount } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <HomeBanner />

      <section className="padding80">
        <div className="container">
          <div className="row popular">
            <div className="col-md-12 text-center">
              <h2>What Can We Help You Find?</h2>
              <p className="mb-5">
                Explore our Popular categories
                <br />
              </p>
            </div>
            {activeCategory &&
              activeCategory.map((item, id) => {
                return (
                  <CategoryCard
                    key={id}
                    id={item._id}
                    category={item.businessCategory}
                    places={categoryCount[id]}
                    icon={item.icon}
                  />
                );
              })}

            <div className="col-md-2">
              <div className="single-category more-categories">
                <div className="icon">
                  <i className="fas fa-ellipsis-h"></i>
                </div>
                <h3>More Categories</h3>
                <Link className="link-btn" to="/listingPage"></Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      {company?.filter((item) => item.featured === "true").length > 0 && (
        <article>
          <div className="container">
            <div className="row featured">
              <div className="col-md-12">
                <h3>Featured Businesses</h3>
                <p>Popular and exclusive listings in our Sanatan directory</p>
              </div>
              {company &&
                company.map((item) => {
                  if (item.featured === "true")
                    return (
                      <ListCard
                        key={item.id}
                        id={item.id}
                        name={item.businessName}
                        category={item.category}
                        location={item.location}
                        phone={item.phone}
                        image={item.image && item.image.url}
                        type="Featured"
                        categoryId={item.categoryId}
                      />
                    );
                })}
            </div>
          </div>
        </article>
      )}
      {/* <article className="padding80">
        <div className="container">
          <div className="row jop-openings">
            <div className="col-md-12 text-center">
              <h3>Latest Jop openings</h3>
              <h4 className="mb-5">Find job openings around you</h4>
            </div>
            {JobData &&
              JobData.map((item, id) => {
                return (
                  <JobCard
                    id={id}
                    key={id}
                    position={item.position}
                    location={item.location}
                    phone={item.phone}
                    image={item.image}
                    jobType={item.jobType}
                  />
                );
              })}
          </div>
        </div>
      </article> */}
      <article className="padding30">
        <div className="container">
          <div className="row join">
            <div className="col-md-12 text-center">
              <h3>Join Sanatan Setu Directory Now</h3>
              <p className="mb-5">
                We connect with targeted customers for greater business
                conversion
              </p>
            </div>
            <div className="col-md-6">
              <div className="item-1 item-1-bg">
                <h2>Looking for additional Business exposure?</h2>
                <p>
                  Tell us what kind of a job you are looking for and stay
                  updated with latest opportunities.
                </p>
                <p className="mb-0">
                  <Link to="/register" className="btn">
                    Register for free
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="item-1 item-2-bg">
                <h2>List your Job requirements or Search Jobs?</h2>
                <p>
                  Tell us what kind of a job you are looking for and stay
                  updated with latest opportunities.
                </p>
                <p className="mb-0">
                  <button disabled className="btn">
                    Coming Soon
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
      <aside className="padding80 background-1">
        <div className="container">
          <div className="row how-it">
            <div className="col-md-12 text-center mb-5">
              <h3>How Sanatan Setu Works</h3>
            </div>
            <div className="col-md-3">
              <div className="item">
                <span>1</span> <img src="assets/images/how1.png" />
                <h4>Create an account</h4>
                <p>
                  Register to add your business's products/services you offer so
                  that other Sanatanis can find & do business with you.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item">
                <span>2</span> <img src="assets/images/how2.png" alt="" />
                <h4>List business, service, or jobs</h4>
                <p>
                  Add your business(es), List Job requirements (coming soon), or
                  List Items for sale (Coming soon).
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item">
                <span>3</span> <img src="assets/images/how3.png" alt="" />
                <h4>Get exposure &amp; leads</h4>
                <p>
                  Every day, thousands of sanatanis search. Get more exposure by
                  featuring your business listing to make it stand out.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="item">
                <span>4</span> <img src="assets/images/how4.png" alt="" />
                <h4>Archive goles</h4>
                <p>
                  Gather inquiries from other sanatanis and solicit feedbacks in
                  order to meet business objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <aside className="padding80">
        <div className="container">
          <div className="row customer">
            <div className="col-md-12">
              <h3>What Our Customer Are Saying?</h3>
              <p>
                The biggest reward is to satisfy our clients and share their
                experience with us
              </p>
            </div>
            <Testimonial />
          </div>
        </div>
      </aside>
    </>
  );
};
