import React from "react";
import moment from "moment";


const SingleReview = ({ review }) => {
  return (
    <>
      <div class="comment mt-3">
        <div class="name-box">
          <div class="row">
            <div class="col-1">
              <img
                src="https://www.webnetcreatives.com/sanatansetu/assets/images/avatar_default.jpg"
                class="img-fluid icon"
                alt="avatar"
              />
            </div>
            <div class="col-6">
              <div class="name">{review.name}</div>
              <div class="data">{moment(review.createdAt).fromNow()}</div>
            </div>
            <div class="col-md-5">
              <ul>
                <li>
                  Service
                  <br />
                  {[...Array(review.serviceReview)].map((num) => (
                    <i class="fas fa-star"></i>
                  ))}
                </li>
                <li>
                  Price
                  <br />
                  {[...Array(review.priceReview)].map((num) => (
                    <i class="fas fa-star"></i>
                  ))}
                </li>
                <li>
                  Quality
                  <br />
                  {[...Array(review.qualityReview)].map((num) => (
                    <i class="fas fa-star"></i>
                  ))}
                </li>
                <li>
                  Location
                  <br />
                  {[...Array(review.locationReview)].map((num) => (
                    <i class="fas fa-star"></i>
                  ))}
                </li>
              </ul>
            </div>

            <div class="border-t"></div>
          </div>
        </div>
        <div class="content">
          {/* <div class="review_title">
            peppyday Left a Comment Secret Food Tours Bangkok
          </div> */}
          <p>{review.comment}</p>
          <div class="review_meta"></div>
          <div class="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default SingleReview;
