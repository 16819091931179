import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Carousel = ({ children }) => {
  return (
    <OwlCarousel
      className="owl-carousel owl-theme owl-loaded owl-drag"
      margin={10}
      lazyLoad
      animateIn={true}
      loop
      autoplay
      responsive={{
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 3,
        },
      }}
      nav
      navText={[
        `<div class='nav-btn prev-slide'>
                    <i class='fas fa-chevron-left'></i>
                </div>`,
        `<div class='nav-btn next-slide'>
                    <i class='fas fa-chevron-right'></i>
                </div>`,
      ]}
    >
      {children}
    </OwlCarousel>
  );
};

export default Carousel;
