import React, { useEffect } from "react";
import { notifySuccess } from "../utils/toast";
import Header from "../components/header/Header";
import { Caption } from "../components/breadcrum/Caption";
import { logout } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import ProfileTab from "../components/tabs/ProfileTab";
import Wishlist from "../components/wishlist/Wishlist";
import MyListings from "../components/mylisting/MyListings";
import NewListing from "../components/mylisting/NewListing";
import EditProfile from "../components/profile/EditProfile";

export const Dashboard = ({ history }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated, history, dispatch]);

  const signOutUser = () => {
    notifySuccess("Logout Successfully");
    dispatch(logout());
  };

  return (
    <>
      <Caption title="Dashboard">
        <Header />
      </Caption>
      <section className="padding80">
        <div className="container">
          <div className="row dashboard">
            <div className="col-md-12">
              <div className="box">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="my-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#my-profile"
                          type="button"
                          role="tab"
                          aria-controls="my-profile"
                          aria-selected="true"
                        >
                          <i className="far fa-user"></i> My Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="edit-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#edit-profile"
                          type="button"
                          role="tab"
                          aria-controls="edit-profile"
                          aria-selected="false"
                        >
                          <i className="fas fa-pencil-alt"></i> Edit Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="new-listing-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#new-listing"
                          type="button"
                          role="tab"
                          aria-controls="new-listing"
                          aria-selected="false"
                        >
                          <i className="far fa-file"></i> Add Business
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="my-listings-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#my-listings"
                          type="button"
                          role="tab"
                          aria-controls="my-listings"
                          aria-selected="false"
                        >
                          <i className="fas fa-layer-group"></i> My Listings
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="wishlist-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#wishlist"
                          type="button"
                          role="tab"
                          aria-controls="wishlist"
                          aria-selected="false"
                        >
                          <i className="far fa-star"></i> My Wishlist
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          onClick={() => signOutUser()}
                        >
                          <i className="fas fa-sign-out-alt"></i>Log out
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <ProfileTab
                        email={user?.email}
                        signOutUser={signOutUser}
                        name={user?.name}
                      />

                      <div
                        className="tab-pane fade"
                        id="edit-profile"
                        role="tabpanel"
                        aria-labelledby="edit-profile-tab"
                      >
                        <EditProfile oldPhone={user?.phone} />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="new-listing"
                        role="tabpanel"
                        aria-labelledby="new-listing-tab"
                      >
                        <NewListing />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="my-listings"
                        role="tabpanel"
                        aria-labelledby="my-listings-tab"
                      >
                        <MyListings />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="wishlist"
                        role="tabpanel"
                        aria-labelledby="wishlist-tab"
                      >
                        <Wishlist />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
