import React, { useState } from "react";
import { Loader } from "../loader/Loader";

import axios from "axios";

import { apiURL, config } from "../../routes/api";
import { notifyError } from "../../utils/toast";

const Modal = ({ companyId, setToogle }) => {
  const [loading, setLoading] = useState(false);

  const deleteCompany = async () => {
    await axios
      .delete(
        `${apiURL}/api/v1/employer/delete/company?id=${companyId}`,
        config
      )
      .then((res) => {
        notifyError(res.data.message);
        setToogle(true);
      })
      .catch((e) => notifyError(e.response.data.message));

    setLoading(false);
  };
  return (
    <>
      <Loader />
      <div
        className="modal fade"
        id={`sanatan${companyId.substring(1, 8)}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Are you sure you want to delete ?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancle
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  deleteCompany();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
