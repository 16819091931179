import React from "react";
import UpdatePassword from "../../dashboard/Business/UpdatePassword";
import UpdatePhone from "../../dashboard/Business/UpdatePhone";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/actions/userAction";
import { useForm } from "react-hook-form";
import { notifyError } from "../../utils/toast";

const EditProfile = ({ history,oldPhone }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.name === user?.name && data.email === user?.email)
      return notifyError("You haven't change any value");

    dispatch(updateProfile(data));
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="text-center">
          <h2>Edit Profile</h2>
          <div className="icon">
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
            <div className="eltd-separator-icon">
              <i className="fas fa-cog"></i>
            </div>
            <div className="eltd-separator">
              <span style={{ width: 85, height: 1 }}></span>
            </div>
          </div>
          <p>
            This is where you can add and change your profile information,
            including your name, social
            <br />
            media links, profile description, and password.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group">
                <label>Name </label>
                <input
                  {...register("name")}
                  type="text"
                  defaultValue={user?.name}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group">
                <label>Email</label>
                <input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  type="text"
                  defaultValue={user?.email}
                  style={errors.email && { border: "1px solid red" }}
                />
                {errors.email && (
                  <label style={{ color: "red", fontSize: ".7rem" }}>
                    {errors.email.message}
                  </label>
                )}
              </div>
            </div>

            <div className="col-md-12 text-center mt-3">
              <div className="input-group">
                <input type="submit" value="Update Profile" />
              </div>
            </div>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <UpdatePassword history={history} />
          <UpdatePhone history={history} oldPhone={oldPhone} />
          {/* <UpdatePhone/> */}
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
